import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Container, Row, Col, Table, Alert, Modal } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { useForm } from 'react-hook-form';
import Pagination from 'react-js-pagination';
import Webcam from 'react-webcam';
import './cadastro.css';
import emptyPhoto from './empty-photo.png'; // Adicione uma imagem de placeholder

const CadastroResponsavel = ({ callApi }) => {
    const [responsaveis, setResponsaveis] = useState([]);
    const [filteredResponsaveis, setFilteredResponsaveis] = useState([]);
    const [editingResponsavel, setEditingResponsavel] = useState(null);
    const [activePage, setActivePage] = useState(1);
    const [message, setMessage] = useState(null);
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();
    const [searchTerm, setSearchTerm] = useState('');
    const [showWebcam, setShowWebcam] = useState(false);
    const [photo, setPhoto] = useState(null);
    const webcamRef = useRef(null);

    useEffect(() => {
        fetchResponsaveis();
    }, []);

    useEffect(() => {
        filterResponsaveis();
    }, [searchTerm, responsaveis]);

    const fetchResponsaveis = async () => {
        try {
            const response = await callApi('GET', 'getResposnavel', { id_responsavel: 0, cpf: '' });
            setResponsaveis(response);
            setFilteredResponsaveis(response);
        } catch (error) {
            console.error('Erro ao obter responsáveis:', error);
        }
    };

    const filterResponsaveis = () => {
        const filtered = responsaveis.filter(responsavel =>
            responsavel.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
            responsavel.cpf.includes(searchTerm)
        );
        setFilteredResponsaveis(filtered);
    };

    const showMessage = (text, variant) => {
        setMessage({ text, variant });
        setTimeout(() => {
            setMessage(null);
        }, 5000);
    };
    const onSubmit = async (data) => {
        try {
            // Adicione o campo photo_base64 aos dados do formulário
            if (photo) {
                data.photo_base64 = photo;
            } else {
                data.photo_base64 = '';
            }
    
            // Define valores padrão para campos comentados
            data.rg = '';  // Exemplo de campo vazio
            data.sexo = '';  // Exemplo de campo com valor padrão
            data.endereco = '';  // Exemplo de campo vazio
            data.cidade = '';  // Exemplo de campo vazio
            data.bairro = '';  // Exemplo de campo vazio
            data.estado = '';  // Exemplo de campo vazio
            data.email = '';  // Exemplo de campo vazio
            data.data_nascimento = '2000-01-01';  // Exemplo de campo zerado
            data.email = '';  
            
    
            if (!data.id_responsavel || data.id_responsavel === 0) {
                data.id_responsavel = 0;
            }
    
            await callApi('POST', 'insertResponsavel', data);
    
            showMessage('Responsável cadastrado com sucesso!', 'success');
            resetForm();
            fetchResponsaveis();
        } catch (error) {
            showMessage('Erro ao cadastrar responsável.', 'danger');
        }
    };
    

    const resetForm = () => {
        reset();
        setPhoto(null);
    };

    useEffect(() => {
        // Define o valor do campo "status" como 1
        setValue('status', 1);
    }, [setValue]);

    const handleEdit = async (responsavel) => {
        setEditingResponsavel(responsavel);
        setValue('id_responsavel', responsavel.id_responsavel || '');
        setValue('nome', responsavel.nome || '');
        setValue('cpf', responsavel.cpf || '');
        setValue('rg', responsavel.rg || '');
        setValue('sexo', responsavel.sexo || 'Masculino');
        setValue('status', responsavel.status || 1);
        setValue('endereco', responsavel.endereco || '');
        setValue('cidade', responsavel.cidade || '');
        setValue('bairro', responsavel.bairro || '');
        setValue('estado', responsavel.estado || '');
        setValue('telefone', responsavel.telefone || '');
        setValue('email', responsavel.email || '');
        const formattedDate = responsavel.data_nascimento ? new Date(responsavel.data_nascimento).toISOString().split('T')[0] : '';
        setValue('data_nascimento', formattedDate);

        // Define o caminho da foto com base no ID do responsável
        const photoUrl = `https://www.igmportariaremota.com.br:3939/responsavel/${responsavel.id_responsavel}.jpg`;

        try {
            // Verifica se a imagem existe
            const response = await fetch(photoUrl);
            if (response.ok) {
                setPhoto(photoUrl); // Atualiza o estado da foto se existir
            } else {
                setPhoto(emptyPhoto); // Define a foto padrão se não existir
            }
        } catch (error) {
            setPhoto(emptyPhoto); // Define a foto padrão em caso de erro
        }
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    const indexOfLastItem = activePage * 5;
    const indexOfFirstItem = indexOfLastItem - 5;
    const currentResponsaveis = filteredResponsaveis.slice(indexOfFirstItem, indexOfLastItem);

    const capturePhoto = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setPhoto(imageSrc);
        setShowWebcam(false);
    };

    return (
        <Container className="cadastro-form">
            <h2 className="title">Cadastro de Responsável</h2>
            {message && (
                <Alert variant={message.variant} onClose={() => setMessage(null)} dismissible>
                    {message.text}
                </Alert>
            )}
            <Row>
                <Col md={6}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row className="justify-content-center">
                            <Col md={8}>
                                <Form.Group controlId="formPhoto" className="text-center">
                                    <Form.Label>Foto</Form.Label>
                                    {photo ? (
                                        <img src={photo} alt="Foto Capturada" className="img-thumbnail mb-3 photo" />
                                    ) : (
                                        <img src={emptyPhoto} alt="Foto Vazia" className="img-thumbnail mb-3 photo" />
                                    )}
                                    <div className="d-flex justify-content-center">
                                        <Button variant="primary" className="submit-button" onClick={() => setShowWebcam(true)}>
                                            Tirar Foto com Webcam
                                        </Button>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formNome">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="nome"
                                        {...register('nome', { required: true })}
                                    />
                                    {errors.nome && <span className="error">Campo obrigatório</span>}
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formCpf">
                                    <Form.Label>CPF</Form.Label>
                                    <InputMask
                                        mask="999.999.999-99"
                                        name="cpf"
                                        {...register('cpf', { required: true })}
                                    >
                                        {(inputProps) => <Form.Control {...inputProps} />}
                                    </InputMask>
                                    {errors.cpf && <span className="error">Campo obrigatório</span>}
                                </Form.Group>
                            </Col>
                        </Row>
                        {/*<Row>
                            <Col md={6}>
                                <Form.Group controlId="formRg">
                                    <Form.Label>RG</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="rg"
                                        {...register('rg')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formSexo">
                                    <Form.Label>Sexo</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="sexo"
                                        {...register('sexo')}
                                    >
                                        <option value="Masculino">Masculino</option>
                                        <option value="Feminino">Feminino</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formEndereco">
                                    <Form.Label>Endereço</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="endereco"
                                        {...register('endereco')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formCidade">
                                    <Form.Label>Cidade</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="cidade"
                                        {...register('cidade')}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formBairro">
                                    <Form.Label>Bairro</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="bairro"
                                        {...register('bairro')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formEstado">
                                    <Form.Label>Estado</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="estado"
                                        {...register('estado')}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                            <Col md={6}>
                                <Form.Group controlId="formEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        name="email"
                                        {...register('email', { required: false })}
                                    />
                                    {errors.email && <span className="error">Campo obrigatório</span>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formDataNascimento">
                                    <Form.Label>Data de Nascimento</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="data_nascimento"
                                        {...register('data_nascimento')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formStatus">
                                    <Form.Label hidden>Status</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="status"
                                        {...register('status')}
                                        hidden
                                    />
                                </Form.Group>
                            </Col>
                        */}
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formTelefone">
                                    <Form.Label>Telefone</Form.Label>
                                    <InputMask
                                        mask="(99) 99999-9999"
                                        name="telefone"
                                        {...register('telefone', { required: true })}
                                    >
                                        {(inputProps) => <Form.Control {...inputProps} />}
                                    </InputMask>
                                    {errors.telefone && <span className="error">Campo obrigatório</span>}
                                </Form.Group>
                            </Col>
                        
                        </Row>
                        <Button type="submit" className="submit-button mt-3">
                            SALVAR
                        </Button>
                    </Form>
                </Col>
                <Col md={6}>
                    <Form.Group controlId="formSearch">
                        <Form.Label>Filtrar</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o nome ou CPF para filtrar"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Form.Group>
                    <Table striped bordered hover className="mt-4">
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentResponsaveis.map((responsavel) => (
                                <tr key={responsavel.id_responsavel}>
                                    <td>{responsavel.nome}</td>
                                    <td>
                                        <Button variant="info" onClick={() => handleEdit(responsavel)}>Editar</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={5}
                        totalItemsCount={filteredResponsaveis.length}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                    />
                </Col>
            </Row>

            <Modal show={showWebcam} onHide={() => setShowWebcam(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Capturar Foto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width="100%"
                        videoConstraints={{
                            width: 720,
                            height: 1280,
                            facingMode: "user"
                        }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowWebcam(false)}>Fechar</Button>
                    <Button variant="primary" className="submit-button" onClick={capturePhoto}>Capturar Foto</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default CadastroResponsavel;
