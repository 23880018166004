import React, { useState } from 'react';
import { Form, Button, Alert } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import './Login.css';

const Login = ({ onLogin }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username === 'admin' && password === 'leiturinha#2024') {
      onLogin({ username, role: 'admin' });
      setIsLoggedIn(true);
    } else if (username === 'operador' && password === 'leiturinha@2024') {
      onLogin({ username, role: 'operador' });
      setIsLoggedIn(true);
    } else {
      setError('Usuário ou senha inválidos');
    }
  };

  if (isLoggedIn) {
    return <Navigate to="/entrada" />;
  }

  return (
    <div className="login-container">
      <div className="login-box">
        <img src='./logos.png' style={{ height: '50px' }} alt="Logo" />
        <h2>Login</h2>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formUsername">
            <Form.Label>Usuário</Form.Label>
            <Form.Control
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="formPassword">
            <Form.Label>Senha</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            Entrar
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default Login;
