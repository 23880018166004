import React, { useState, useEffect, useRef } from 'react';
import { Form, Button, Container, Row, Col, Table, Alert, Modal } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { useForm } from 'react-hook-form';
import Pagination from 'react-js-pagination';
import Webcam from 'react-webcam';
import './cadastro.css';
import emptyPhoto from './empty-photo.png'; // Adicione uma imagem de placeholder

const CadastroCrianca = ({ callApi }) => {
    const [criancas, setCriancas] = useState([]);
    const [filteredCriancas, setFilteredCriancas] = useState([]);
    const [editingCrianca, setEditingCrianca] = useState(null);
    const [activePage, setActivePage] = useState(1);
    const [responsavelCPF, setResponsavelCPF] = useState('');
    const [responsavelNome, setResponsavelNome] = useState('');
    const [message, setMessage] = useState(null);
    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();
    const [responsavelId, setResponsavelId] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [showWebcam, setShowWebcam] = useState(false);
    const [photo, setPhoto] = useState(null);
    const webcamRef = useRef(null);

    const indexOfLastItem = activePage * 5;
    const indexOfFirstItem = indexOfLastItem - 5;
    const currentCriancas = filteredCriancas.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
        fetchCriancas();
    }, []);

    useEffect(() => {
        filterCriancas();
    }, [searchTerm, criancas]);

    const fetchCriancas = async () => {
        try {
            const response = await callApi('GET', 'getCrianca', { id_crianca: 0, id_responsavel: 0, cpf: '' });
            setCriancas(response);
            setFilteredCriancas(response);
        } catch (error) {
            console.error('Erro ao obter crianças:', error);
        }
    };

    const filterCriancas = () => {
        const filtered = criancas.filter(crianca =>
            crianca.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
            crianca.cpf.includes(searchTerm)
        );
        setFilteredCriancas(filtered);
    };

    const showMessage = (text, variant) => {
        setMessage({ text, variant });
        setTimeout(() => {
            setMessage(null);
        }, 5000);
    };

    const onSubmit = async (data) => {
        try {
            let response;

            if (!data.id_crianca || data.id_crianca === 0) {
                data.id_crianca = 0;
            }

            if (!responsavelId) {
                showMessage('Por favor, insira um CPF válido do responsável.', 'danger');
                return;
            }

            data.id_responsavel = responsavelId;

            if (photo) {
                data.photo_base64 = photo;
            } else {
                data.photo_base64 = '';
            }

            response = await callApi('POST', 'insertCrianca', data);

            showMessage('Criança cadastrada com sucesso!', 'success');
            resetForm(); // Reset all form fields
            fetchCriancas();
        } catch (error) {
            showMessage('Erro ao cadastrar criança.', 'danger');
        }
    };

    useEffect(() => {
        // Define o valor do campo "status" como 1
        setValue('status', 1);
    }, [setValue]);

    const resetForm = () => {
        reset();
        setResponsavelId(null);
        setResponsavelCPF('');
        setResponsavelNome('');
        setPhoto(null);
        setEditingCrianca(null);
    };

    const handleEdit = async (crianca) => {
        setEditingCrianca(crianca);
        setValue('id_crianca', crianca.id_crianca);
        setValue('id_responsavel', crianca.id_responsavel);
        setValue('nome', crianca.nome);
        setValue('cpf', crianca.cpf);
        setValue('sexo', crianca.sexo);
        setValue('status', crianca.status);
        const formattedDate = new Date(crianca.data_nascimento).toISOString().split('T')[0];
        setValue('data_nascimento', formattedDate);

        // Define o caminho da foto com base no ID da criança
        const photoUrl = `https://www.igmportariaremota.com.br:3939/crianca/${crianca.id_crianca}.jpg`;

        try {
            // Verifica se a imagem existe
            fetch(photoUrl)
                .then(response => {
                    if (response.ok) {
                        setPhoto(photoUrl); // Atualiza o estado da foto se existir
                    } else {
                        setPhoto(emptyPhoto); // Define a foto padrão se não existir
                    }
                })
                .catch(() => {
                    setPhoto(emptyPhoto); // Define a foto padrão em caso de erro
                });
        } catch (error) {
            setPhoto(emptyPhoto); // Define a foto padrão em caso de erro
        }

        // Buscar informações do responsável
        try {
            const response = await callApi('GET', 'getResposnavel', { id_responsavel: crianca.id_responsavel, cpf: '' });
            if (response && response.length > 0) {
                const responsavel = response[0];
                setResponsavelId(responsavel.id_responsavel);
                setResponsavelCPF(responsavel.cpf);
                setResponsavelNome(responsavel.nome);
            } else {
                setResponsavelId(null);
                setResponsavelCPF('');
                setResponsavelNome('');
            }
        } catch (error) {
            console.error('Erro ao buscar responsável:', error);
            showMessage('Erro ao buscar responsável.', 'danger');
        }
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    const handleResponsavelSearch = async (event) => {
        const cpf = event.target.value;
        try {
            var response = await callApi('GET', 'getResposnavel', { id_responsavel: 0, cpf: cpf });
            response = response[0];
            if (response && response.id_responsavel) {
                setResponsavelId(response.id_responsavel);
                setResponsavelNome(response.nome);  // Supondo que o backend também retorne o nome do responsável
            } else {
                setResponsavelId(null);
                setResponsavelNome('');
                showMessage('Nenhum responsável encontrado para o CPF fornecido.', 'danger');
            }
        } catch (error) {
            console.error('Erro ao buscar responsável:', error);
            showMessage('Erro ao buscar responsável.', 'danger');
        }
    };

    const capturePhoto = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setPhoto(imageSrc);
        setShowWebcam(false);
    };

    return (
        <Container className="cadastro-form">
            <h2 className="title">Cadastro de Criança</h2>
            {message && (
                <Alert variant={message.variant} onClose={() => setMessage(null)} dismissible>
                    {message.text}
                </Alert>
            )}
            <Row>
                <Col md={6}>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col md={12}>
                                <Form.Group controlId="formResponsavelCpf">
                                    <Form.Label>CPF do Responsável</Form.Label>
                                    <InputMask
                                        mask="999.999.999-99"
                                        name="responsavelCpf"
                                        onBlur={handleResponsavelSearch}
                                        value={responsavelCPF}
                                        onChange={(e) => setResponsavelCPF(e.target.value)}
                                    >
                                        {(inputProps) => <Form.Control {...inputProps} />}
                                    </InputMask>
                                    {!responsavelId && <span className="error">CPF do responsável não encontrado</span>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form.Group controlId="formResponsavelNome">
                                    <Form.Label>Nome do Responsável</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="responsavelNome"
                                        value={responsavelNome}
                                        readOnly
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Form.Group controlId="formPhoto" className="text-center">
                                    <Form.Label>Foto</Form.Label>
                                    {photo ? (
                                        <img src={photo} alt="Foto Capturada" className="img-thumbnail mb-3 photo" />
                                    ) : (
                                        <img src={emptyPhoto} alt="Foto Vazia" className="img-thumbnail mb-3 photo" />
                                    )}
                                    <div className="d-flex justify-content-center">
                                        <Button variant="primary" className="submit-button" onClick={() => setShowWebcam(true)}>
                                            Tirar Foto com Webcam
                                        </Button>
                                    </div>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formNome">
                                    <Form.Label>Nome da Criança</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="nome"
                                        {...register('nome', { required: true })}
                                    />
                                    {errors.nome && <span className="error">Campo obrigatório</span>}
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formCpfCrianca">
                                    <Form.Label>CPF da Criança</Form.Label>
                                    <InputMask
                                        mask="999.999.999-99"
                                        name="cpf"
                                        {...register('cpf', { required: true })}
                                    >
                                        {(inputProps) => <Form.Control {...inputProps} />}
                                    </InputMask>
                                    {errors.cpf && <span className="error">Campo obrigatório</span>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId="formSexo">
                                    <Form.Label>Sexo</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="sexo"
                                        {...register('sexo')}
                                    >
                                        <option value="Masculino">Masculino</option>
                                        <option value="Feminino">Feminino</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formDataNascimento">
                                    <Form.Label>Data de Nascimento</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="data_nascimento"
                                        {...register('data_nascimento')}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId="formStatus">
                                    <Form.Label hidden> Status</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="status"
                                        {...register('status')}
                                        hidden />
                                </Form.Group>
                            </Col>


                        </Row>
                        <br></br>
                        <Button type="submit" className="submit-button">
                            SALVAR
                        </Button>
                    </Form>
                </Col>
                <Col md={6}>
                    <Form.Group controlId="formSearch">
                        <Form.Label>Filtrar</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o nome ou CPF para filtrar"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Form.Group>
                    <Table striped bordered hover className="mt-4">
                        <thead>
                            <tr>
                                <th>Nome</th>
                                {/*<th>CPF</th>*/}
                                <th>Sexo</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentCriancas.map((crianca) => (
                                <tr key={crianca.id_crianca}>
                                    <td>{crianca.nome}</td>
                                    {/*<td>crianca.cpf</td>/}*/}
                                    <td>{crianca.sexo}</td>
                                    <td>
                                        <Button variant="info" onClick={() => handleEdit(crianca)}>Editar</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={5}
                        totalItemsCount={filteredCriancas.length}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                    />
                </Col>
            </Row>

            <Modal show={showWebcam} onHide={() => setShowWebcam(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Capturar Foto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        width="100%"
                        videoConstraints={{
                            width: 720,
                            height: 1280,
                            facingMode: "user"
                        }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowWebcam(false)}>Fechar</Button>
                    <Button variant="primary" className="submit-button" onClick={capturePhoto}>Capturar Foto</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default CadastroCrianca;
