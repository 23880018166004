import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import axios from 'axios';
import { Modal, Spinner, Dropdown } from 'react-bootstrap';

import CadastroResponsavel from './components/CadastroResponsavel';
import CadastroCrianca from './components/CadastroCrianca';
import CadastroTag from './components/CadastroTag';
import CadastroEquipamento from './components/CadastroEquipamento';
import Configuracoes from './components/Configuracoes';
import CadastroResponsavelCrianca from './components/CadastroResponsavelCrianca';
import CadastroFechamento from './components/CadastroFechamento';
import CadastroPortaria from './components/CadastroPortaria';
import Entrada from './components/Entrada';
import Login from './components/Login';

import './App.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faChild, faTags, faCogs, faSignInAlt, faSignOutAlt, faLaptop, faKey } from '@fortawesome/free-solid-svg-icons';

const callApi = async (method, endpoint, data = {}, setIsLoading) => {
   const url = `https://www.igmportariaremota.com.br:3939/${endpoint}`;
  //const url = `http://127.0.0.1:3838/${endpoint}`;
  setIsLoading(true);
  try {
    const response = method === 'GET'
      ? await axios.get(url, { params: data })
      : await axios.post(url, data);
    return response.data;
  } catch (error) {
    console.error(`Erro ao fazer chamada ${method} para ${endpoint}`, error);
    throw error;
  } finally {
    setIsLoading(false);
  }
};

const App = () => {
  const [auth, setAuth] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Definindo inicialmente como false

  useEffect(() => {
    const storedAuth = localStorage.getItem('auth');
    if (storedAuth) {
      setAuth(JSON.parse(storedAuth));
      setIsLoggedIn(true); // Definindo como true se há autenticação válida
    }
  }, []);

  const handleLogin = (user) => {
    setAuth(user);
    localStorage.setItem('auth', JSON.stringify(user));
    setIsLoggedIn(true); // Definindo como true ao fazer login
  };

  const handleLogout = () => {
    setAuth(null);
    localStorage.removeItem('auth');
    setIsLoggedIn(false); // Definindo como false ao fazer logout
    
    // Redirecionamento usando window.location.href
    window.location.href = 'https://www.igmportariaremota.com.br/';
  
    // Retornar null ou outra coisa se necessário
    return null;
  };
  

  return (
    <Router>
      <div className="app-container">
        <header className="header">
          <div style={{ textAlign: 'center' }}>
            <img src='./logos.png' style={{ height: '50px' }} alt="Logo" />
          </div>
          {auth && (
            <Dropdown className="user-dropdown">
              <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                {auth.username}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={handleLogout}>Sair</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </header>

        <Modal show={isLoading} backdrop="static" keyboard={false} centered>
          <Modal.Body className="d-flex justify-content-center align-items-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Carregando...</span>
            </Spinner>
          </Modal.Body>
        </Modal>

        <div className="menu-content-container">
          {isLoggedIn && ( // Mostrar menu apenas se estiver logado
            <nav className="menu-lateral">
              <b style={{ color: 'white' }}>OPERAÇÕES</b>
              <ul>
                <li>
                  <Link to="/entrada">
                    <FontAwesomeIcon icon={faSignInAlt} />
                    <span>Entrada</span>
                  </Link>
                </li>
                {auth.role === 'admin' && (
                  <li>
                    <Link to="/saida">
                      <FontAwesomeIcon icon={faSignOutAlt} />
                      <span>Saída</span>
                    </Link>
                  </li>
                )}
              </ul>
              <hr></hr>
              <b style={{ color: 'white' }}>CADASTROS</b>
              <ul>
                <li>
                  <Link to="/responsaveis">
                    <FontAwesomeIcon icon={faUser} />
                    <span>Responsáveis</span>
                  </Link>
                </li>
                <li>
                  <Link to="/criancas">
                    <FontAwesomeIcon icon={faChild} />
                    <span>Crianças</span>
                  </Link>
                </li>
                {auth.role === 'admin' && (
                  <>
                    <li>
                      <Link to="/tags">
                        <FontAwesomeIcon icon={faTags} />
                        <span>Tags</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/equipamentos">
                        <FontAwesomeIcon icon={faLaptop} />
                        <span>Equipamentos</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/portaria">
                        <FontAwesomeIcon icon={faKey} />
                        <span>Portarias</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/configuracoes">
                        <FontAwesomeIcon icon={faCogs} />
                        <span>Configurações</span>
                      </Link>
                    </li>
                  </>
                )}
              </ul>
            </nav>
          )}

          <div className="content">
            <Routes>
              <Route path="/" element={<Login onLogin={handleLogin} />} />
              <Route path="/entrada" element={<CadastroResponsavelCrianca callApi={(method, endpoint, data) => callApi(method, endpoint, data, setIsLoading)} />} />
              {auth?.role === 'admin' && (
                <Route path="/saida" element={<CadastroFechamento callApi={(method, endpoint, data) => callApi(method, endpoint, data, setIsLoading)} />} />
              )}
              <Route path="/responsaveis" element={<CadastroResponsavel callApi={(method, endpoint, data) => callApi(method, endpoint, data, setIsLoading)} />} />
              <Route path="/criancas" element={<CadastroCrianca callApi={(method, endpoint, data) => callApi(method, endpoint, data, setIsLoading)} />} />
              {auth?.role === 'admin' && (
                <>
                  <Route path="/tags" element={<CadastroTag callApi={(method, endpoint, data) => callApi(method, endpoint, data, setIsLoading)} />} />
                  <Route path="/equipamentos" element={<CadastroEquipamento callApi={(method, endpoint, data) => callApi(method, endpoint, data, setIsLoading)} />} />
                  <Route path="/portaria" element={<CadastroPortaria callApi={(method, endpoint, data) => callApi(method, endpoint, data, setIsLoading)} />} />
                  <Route path="/configuracoes" element={<Configuracoes callApi={(method, endpoint, data) => callApi(method, endpoint, data, setIsLoading)} />} />
                </>
              )}
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default App;
