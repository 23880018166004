import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Table, Alert } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import './cadastro.css';

const CadastroEquipamento = ({ callApi }) => {
    const [equipamentos, setEquipamentos] = useState([]);
    const [filteredEquipamentos, setFilteredEquipamentos] = useState([]);
    const [equipamento, setEquipamento] = useState({
        id_equipamento: 0,
        nome: '',
        ip: '',
        porta: '',
        status: '',
        tipo_integracao: ''
    });
    const [activePage, setActivePage] = useState(1);
    const [message, setMessage] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const indexOfLastItem = activePage * 5;
    const indexOfFirstItem = indexOfLastItem - 5;
    const currentEquipamentos = filteredEquipamentos.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
        fetchEquipamentos();
    }, []);

    useEffect(() => {
        filterEquipamentos();
    }, [searchTerm, equipamentos]);

    const fetchEquipamentos = async () => {
        try {
            const response = await callApi('GET', 'getEquipamento', { id_equipamento: 0 });
            setEquipamentos(response);
            setFilteredEquipamentos(response);
        } catch (error) {
            console.error('Erro ao obter equipamentos:', error);
        }
    };

    const filterEquipamentos = () => {
        const filtered = equipamentos.filter(equipamento =>
            equipamento.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
            equipamento.ip.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredEquipamentos(filtered);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEquipamento(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await callApi('POST', 'insertEquipamento', equipamento);
            showMessage('Equipamento cadastrado com sucesso!', 'success');
            setEquipamento({
                id_equipamento: 0,
                nome: '',
                ip: '',
                porta: '',
                status: '',
                tipo_integracao: ''
            });
            fetchEquipamentos();
        } catch (error) {
            showMessage('Erro ao cadastrar equipamento.', 'danger');
        }
    };

    const handleEdit = (equipamento) => {
        setEquipamento(equipamento);
    };

    const showMessage = (text, variant) => {
        setMessage({ text, variant });
        setTimeout(() => {
            setMessage(null);
        }, 5000);
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    return (
        <Container className="cadastro-form">
            <h2 className="title">Cadastro de Equipamento</h2>
            {message && (
                <Alert variant={message.variant} onClose={() => setMessage(null)} dismissible>
                    {message.text}
                </Alert>
            )}
            <Row>
                <Col md={6}>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formNome">
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                                type="text"
                                name="nome"
                                value={equipamento.nome}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formIp">
                            <Form.Label>IP</Form.Label>
                            <Form.Control
                                type="text"
                                name="ip"
                                value={equipamento.ip}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formPorta">
                            <Form.Label>Porta</Form.Label>
                            <Form.Control
                                type="text"
                                name="porta"
                                value={equipamento.porta}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formStatus">
                            <Form.Label>Status</Form.Label>
                            <Form.Control
                                type="text"
                                name="status"
                                value={equipamento.status}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formTipoIntegracao">
                            <Form.Label>Tipo de Integração</Form.Label>
                            <Form.Control
                                type="text"
                                name="tipo_integracao"
                                value={equipamento.tipo_integracao}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="submit-button">
                            Cadastrar
                        </Button>
                    </Form>
                </Col>
                <Col md={6}>
                    <Form.Group controlId="formSearch">
                        <Form.Label>Filtrar</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o nome ou IP para filtrar"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Form.Group>
                    <Table striped bordered hover className="mt-4">
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>IP</th>
                                <th>Porta</th>
                                <th>Status</th>
                                <th>Tipo de Integração</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentEquipamentos.map((equipamento) => (
                                <tr key={equipamento.id_equipamento}>
                                    <td>{equipamento.nome}</td>
                                    <td>{equipamento.ip}</td>
                                    <td>{equipamento.porta}</td>
                                    <td>{equipamento.status}</td>
                                    <td>{equipamento.tipo_integracao}</td>
                                    <td>
                                        <Button variant="info" onClick={() => handleEdit(equipamento)}>Editar</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={5}
                        totalItemsCount={filteredEquipamentos.length}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default CadastroEquipamento;
