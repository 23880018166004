import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Table, Alert } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import './cadastro.css';

const CadastroPortaria = ({ callApi }) => {
    const [portarias, setPortarias] = useState([]);
    const [filteredPortarias, setFilteredPortarias] = useState([]);
    const [equipamentos, setEquipamentos] = useState([]);
    const [portaria, setPortaria] = useState({
        id_portaria: 0,
        id_equipamento: '',
        nome: '',
        rele: ''
    });
    const [activePage, setActivePage] = useState(1);
    const [message, setMessage] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const indexOfLastItem = activePage * 5;
    const indexOfFirstItem = indexOfLastItem - 5;
    const currentPortarias = filteredPortarias.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
        fetchPortarias();
        fetchEquipamentos();
    }, []);

    useEffect(() => {
        filterPortarias();
    }, [searchTerm, portarias]);

    const fetchPortarias = async () => {
        try {
            const response = await callApi('GET', 'getPortaria', { id_portaria: 0, id_equipamento: 0 });
            setPortarias(response);
            setFilteredPortarias(response);
        } catch (error) {
            console.error('Erro ao obter portarias:', error);
        }
    };

    const fetchEquipamentos = async () => {
        try {
            const response = await callApi('GET', 'getEquipamento', { id_equipamento: 0 });
            setEquipamentos(response);
        } catch (error) {
            console.error('Erro ao obter equipamentos:', error);
        }
    };

    const filterPortarias = () => {
        const filtered = portarias.filter(portaria =>
            portaria.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
            portaria.rele.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredPortarias(filtered);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPortaria(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await callApi('POST', 'insertPortaria', portaria);
            showMessage('Portaria cadastrada com sucesso!', 'success');
            setPortaria({
                id_portaria: 0,
                id_equipamento: '',
                nome: '',
                rele: ''
            });
            fetchPortarias();
        } catch (error) {
            showMessage('Erro ao cadastrar portaria.', 'danger');
        }
    };

    const handleEdit = (portaria) => {
        setPortaria(portaria);
    };

    const showMessage = (text, variant) => {
        setMessage({ text, variant });
        setTimeout(() => {
            setMessage(null);
        }, 5000);
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    return (
        <Container className="cadastro-form">
            <h2 className="title">Cadastro de Portaria</h2>
            {message && (
                <Alert variant={message.variant} onClose={() => setMessage(null)} dismissible>
                    {message.text}
                </Alert>
            )}
            <Row>
                <Col md={6}>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formEquipamento">
                            <Form.Label>Equipamento</Form.Label>
                            <Form.Control
                                as="select"
                                name="id_equipamento"
                                value={portaria.id_equipamento}
                                onChange={handleChange}
                                required
                            >
                                <option value="">Selecione o Equipamento</option>
                                {equipamentos.map((equipamento) => (
                                    <option key={equipamento.id_equipamento} value={equipamento.id_equipamento}>
                                        {equipamento.nome}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="formNome">
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                                type="text"
                                name="nome"
                                value={portaria.nome}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formNome">
                        <Form.Label>Relé</Form.Label>
                            <Form.Control
                                name="rele"
                                value={portaria.rele}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="submit-button">
                            Cadastrar
                        </Button>
                    </Form>
                </Col>
                <Col md={6}>
                    <Form.Group controlId="formSearch">
                        <Form.Label>Filtrar</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o nome ou rele para filtrar"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Form.Group>
                    <Table striped bordered hover className="mt-4">
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Rele</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPortarias.map((portaria) => (
                                <tr key={portaria.id_portaria}>
                                    <td>{portaria.nome}</td>
                                    <td>{portaria.rele}</td>
                                    <td>
                                        <Button variant="info" onClick={() => handleEdit(portaria)}>Editar</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={5}
                        totalItemsCount={filteredPortarias.length}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default CadastroPortaria;
