import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Table, Button, Form, Modal, Alert } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faEye } from '@fortawesome/free-solid-svg-icons';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import './cadastro.css';

const RelatorioFechamentos = ({ callApi }) => {
    const [fechamentos, setFechamentos] = useState([]);
    const [filteredFechamentos, setFilteredFechamentos] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [activePage, setActivePage] = useState(1);
    const [showModal, setShowModal] = useState(false);
    const [selectedFechamento, setSelectedFechamento] = useState(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const indexOfLastItem = activePage *10;
    const indexOfFirstItem = indexOfLastItem - 10;
    const currentFechamentos = filteredFechamentos.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
        fetchFechamentos();
    }, []);

    useEffect(() => {
        filterFechamentos();
    }, [searchTerm, startDate, endDate, fechamentos]);

    const fetchFechamentos = async () => {
        try {
            var response = await callApi('GET', 'getFechamentos');
           // response = response[0];
            console.log(response);
            setFechamentos(response);
            setFilteredFechamentos(response);
        } catch (error) {
            console.error('Erro ao obter fechamentos:', error);
        }
    };

    const filterFechamentos = () => {
        const filtered = fechamentos.filter(fechamento =>
            (fechamento.nome_responsavel.toLowerCase().includes(searchTerm.toLowerCase()) ||
                fechamento.nome_crianca.toLowerCase().includes(searchTerm.toLowerCase())) &&
            (!startDate || new Date(fechamento.data) >= new Date(startDate)) &&
            (!endDate || new Date(fechamento.data) <= new Date(endDate))
        );
        setFilteredFechamentos(filtered);
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    const handleView = (fechamento) => {
        setSelectedFechamento(fechamento);
        setShowModal(true);
    };

    const handlePrint = (fechamento) => {
        const printWindow = window.open('', '_blank');
        printWindow.document.write(`
            <html>
                <head>
                    <style>
                        @page { size: 80mm auto; margin: 0mm; }
                        body {
                            width: 80mm;
                            font-family: Arial, sans-serif;
                            margin: 0;
                            padding: 0;
                        }
                        .comprovante {
                            padding: 10mm;
                        }
                        .comprovante img {
                            width: 100%;
                        }
                        .comprovante p {
                            margin: 0;
                            padding: 0;
                        }
                    </style>
                </head>
                <body>
                    <div class="comprovante">
                        <img src="logos.png" width="130px" alt="Logo" />
                        <hr>
                        <p style='font-size:16px;'>CÓDIGO ENTRADA: ${fechamento.id_crianca_tag}</p>
                        <hr>
                        <p>Responsável:<br> ${fechamento.nome_responsavel}</p>
                        <hr>
                        <p>Criança:<br> ${fechamento.nome_crianca}</p>
                        <hr>
                        <p>Entrada:<br> ${fechamento.hora_inicio}</p>
                        <hr>
                        <p>Saída:<br> ${fechamento.hora_final}</p>
                        <hr>
                        <p>Tempo Total:<br> ${fechamento.minutos_passados} Minutos</p>
                        <hr>
                        <p>Preço Total:<br> R$ ${fechamento.valor}</p>
                        <hr>
                        <p>Obrigado, Volte Sempre!</p>
                    </div>
                </body>
            </html>
        `);
        printWindow.document.close();
        printWindow.focus();
        setTimeout(() => {
            printWindow.print();
            printWindow.close();
        }, 1000);
    };

    const exportPDF = () => {
        const doc = new jsPDF();
        const total = filteredFechamentos.reduce((acc, fechamento) => acc + parseFloat(fechamento.valor), 0);
        doc.text('Relatório de Fechamentos', 14, 16);
        doc.autoTable({
            startY: 20,
            head: [['ID', 'Responsável', 'Criança', 'Entrada', 'Saída', 'Valor']],
            body: filteredFechamentos.map(fechamento => [
                fechamento.id_crianca_tag,
                fechamento.nome_responsavel,
                fechamento.nome_crianca,
                fechamento.hora_inicio,
                fechamento.hora_final,
                fechamento.valor
            ]),
        });
        doc.text(`Total: R$ ${total.toFixed(2)}`, 14, doc.lastAutoTable.finalY + 10);
        doc.save('relatorio_fechamentos.pdf');
    };

    return (
        <Container className="relatorio-fechamentos">
            <h2 className="title">Relatório de Fechamentos</h2>
            <Row className="mb-3">
                <Col md={4}>
                    <Form.Group controlId="formSearch">
                        <Form.Control
                            type="text"
                            placeholder="Pesquisar..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group controlId="formStartDate">
                        <Form.Control
                            type="date"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col md={3}>
                    <Form.Group controlId="formEndDate">
                        <Form.Control
                            type="date"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col md={2}>
                    <Button variant="success" onClick={exportPDF}>
                        Exportar PDF
                    </Button>
                </Col>
            </Row>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Responsável</th>
                        <th>Criança</th>
                        <th>Entrada</th>
                        <th>Saída</th>
                        <th>Valor</th>
                        <th>Ações</th>
                    </tr>
                </thead>
                <tbody>
                    {currentFechamentos.map((fechamento) => (
                        <tr key={fechamento.id_crianca_tag}>
                            <td>{fechamento.id_crianca_tag}</td>
                            <td>{fechamento.nome_responsavel}</td>
                            <td>{fechamento.nome_crianca}</td>
                            <td>{fechamento.hora_inicio}</td>
                            <td>{fechamento.hora_final}</td>
                            <td>{fechamento.valor}</td>
                            <td>
                                <Button variant="info" onClick={() => handleView(fechamento)} className="mr-2">
                                    <FontAwesomeIcon icon={faEye} />
                                </Button>
                                <Button variant="primary" onClick={() => handlePrint(fechamento)}>
                                    <FontAwesomeIcon icon={faPrint} />
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Pagination
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={filteredFechamentos.length}
                pageRangeDisplayed={10}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
            />

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Detalhes do Fechamento</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedFechamento && (
                        <div>
                            <Row>
                                <Col md={6}>
                                    <img src={`https://www.igmportariaremota.com.br:3939/responsavel/${selectedFechamento.id_responsavel}.jpg`} alt="Responsável" width="100%" />
                                </Col>
                                <Col md={6}>
                                    <img src={`https://www.igmportariaremota.com.br:3939/crianca/${selectedFechamento.id_crianca}.jpg`} alt="Criança" width="100%" />
                                </Col>
                            </Row>
                            <hr />
                            <Row>
                                <Col md={6}>
                                    <b>Responsável:</b>
                                    <p>
                                        {selectedFechamento.nome_responsavel}<br />
                                        {selectedFechamento.cpf_responsavel}
                                    </p>
                                </Col>
                                <Col md={6}>
                                    <b>Criança:</b>
                                    <p>
                                        {selectedFechamento.nome_crianca}<br />
                                        {selectedFechamento.cpf_crianca}
                                    </p>
                                </Col>
                            </Row>
                            <hr />
                            <p>Tag: <b>{selectedFechamento.tag}</b></p>
                            <p>Entrada: <b>{selectedFechamento.hora_inicio}</b></p>
                            <p>Saída: <b>{selectedFechamento.hora_final}</b></p>
                            <p>Tempo Total: <b>{selectedFechamento.minutos_passados} Minutos</b></p>
                            <p>Preço Total: <b>R$ {selectedFechamento.valor}</b></p>
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        </Container>
    );
};

export default RelatorioFechamentos;
