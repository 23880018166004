import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Table, Alert } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import './cadastro.css';

const CadastroTag = ({ callApi }) => {
    const [tags, setTags] = useState([]);
    const [filteredTags, setFilteredTags] = useState([]);
    const [tag, setTag] = useState({
        id_tag: 0,
        nome: '',
        numero: '',
        status: 1
    });
    const [activePage, setActivePage] = useState(1);
    const [message, setMessage] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');

    const indexOfLastItem = activePage * 5;
    const indexOfFirstItem = indexOfLastItem - 5;
    const currentTags = filteredTags.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
        fetchTags();
    }, []);

    useEffect(() => {
        filterTags();
    }, [searchTerm, tags]);

    const fetchTags = async () => {
        try {
            const response = await callApi('GET', 'getTag', { id_tag: 0, numero: '' });
            setTags(response);
            setFilteredTags(response);
        } catch (error) {
            console.error('Erro ao obter tags:', error);
        }
    };

    const filterTags = () => {
        const filtered = tags.filter(tag =>
            tag.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
            tag.numero.includes(searchTerm)
        );
        setFilteredTags(filtered);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setTag(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await callApi('POST', 'insertTag', tag);
            showMessage('Tag cadastrada com sucesso!', 'success');
            setTag({
                id_tag: 0,
                nome: '',
                numero: '',
                status: 1
            });
            fetchTags();
        } catch (error) {
            showMessage('Erro ao cadastrar tag.', 'danger');
        }
    };

    const showMessage = (text, variant) => {
        setMessage({ text, variant });
        setTimeout(() => {
            setMessage(null);
        }, 5000);
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    return (
        <Container className="cadastro-form">
            <h2 className="title">Cadastro de Tag</h2>
            {message && (
                <Alert variant={message.variant} onClose={() => setMessage(null)} dismissible>
                    {message.text}
                </Alert>
            )}
            <Row>
                <Col md={6}>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formNome">
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                                type="text"
                                name="nome"
                                value={tag.nome}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formNumero">
                            <Form.Label>Número</Form.Label>
                            <Form.Control
                                type="text"
                                name="numero"
                                value={tag.numero}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Button type="submit" className="submit-button">
                            Cadastrar
                        </Button>
                    </Form>
                </Col>
                <Col md={6}>
                    <Form.Group controlId="formSearch">
                        <Form.Label>Filtrar</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o nome ou número para filtrar"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Form.Group>
                    <Table striped bordered hover className="mt-4">
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Número</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentTags.map((tag) => (
                                <tr key={tag.id_tag}>
                                    <td>{tag.nome}</td>
                                    <td>{tag.numero}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={5}
                        totalItemsCount={filteredTags.length}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default CadastroTag;
