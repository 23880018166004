import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Table, Alert, Modal } from 'react-bootstrap';
import Pagination from 'react-js-pagination';
import InputMask from 'react-input-mask';
import './cadastro.css';

const CadastroConfiguracao = ({ callApi }) => {
    const [configuracoes, setConfiguracoes] = useState([]);
    const [filteredConfiguracoes, setFilteredConfiguracoes] = useState([]);
    const [configuracao, setConfiguracao] = useState({
        id_configuracao: 0,
        nome: '',
        descricao: '',
        status: ''
    });
    const [configuracaoTaxa, setConfiguracaoTaxa] = useState({
        id_configuracao_taxa: 0,
        id_configuracao: 0,
        de: '',
        ate: '',
        valor: ''
    });
    const [configuracaoTaxas, setConfiguracaoTaxas] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [message, setMessage] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [showModal, setShowModal] = useState(false);

    const indexOfLastItem = activePage * 5;
    const indexOfFirstItem = indexOfLastItem - 5;
    const currentConfiguracoes = filteredConfiguracoes.slice(indexOfFirstItem, indexOfLastItem);

    useEffect(() => {
        fetchConfiguracoes();
    }, []);

    useEffect(() => {
        filterConfiguracoes();
    }, [searchTerm, configuracoes]);

    const fetchConfiguracoes = async () => {
        try {
            const response = await callApi('GET', 'getConfiguracao', { id_configuracao: 0 });
            setConfiguracoes(response);
            setFilteredConfiguracoes(response);
        } catch (error) {
            console.error('Erro ao obter configurações:', error);
        }
    };

    const filterConfiguracoes = () => {
        const filtered = configuracoes.filter(configuracao =>
            configuracao.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
            configuracao.descricao.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredConfiguracoes(filtered);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setConfiguracao(prevState => ({ ...prevState, [name]: value }));
    };

    const handleTaxaChange = (e) => {
        const { name, value } = e.target;
        setConfiguracaoTaxa(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await callApi('POST', 'insertConfiguracao', configuracao);
            showMessage('Configuração cadastrada com sucesso!', 'success');
            setConfiguracao({
                id_configuracao: 0,
                nome: '',
                descricao: '',
                status: ''
            });
            fetchConfiguracoes();
        } catch (error) {
            showMessage('Erro ao cadastrar configuração.', 'danger');
        }
    };

    const handleTaxaSubmit = async (e) => {
        e.preventDefault();
        try {
            configuracaoTaxa.valor = parseFloat(configuracaoTaxa.valor.replace(',', '.'));
            await callApi('POST', 'insertConfiguracaoTaxa', configuracaoTaxa);
            showMessage('Configuração de taxa cadastrada com sucesso!', 'success');
            fetchConfiguracaoTaxas(configuracaoTaxa.id_configuracao);
            setConfiguracaoTaxa({
                id_configuracao_taxa: 0,
                id_configuracao: configuracaoTaxa.id_configuracao,
                de: '',
                ate: '',
                valor: ''
            });
            setShowModal(false); // Fechar o modal após salvar
        } catch (error) {
            showMessage('Erro ao cadastrar configuração de taxa.', 'danger');
        }
    };

    const handleEdit = (configuracao) => {
        setConfiguracao(configuracao);
    };

    const handleConfigurar = async (configuracao) => {
        setConfiguracaoTaxa({
            ...configuracaoTaxa,
            id_configuracao: configuracao.id_configuracao
        });
        await fetchConfiguracaoTaxas(configuracao.id_configuracao);
        setShowModal(true);
    };

    const fetchConfiguracaoTaxas = async (id_configuracao) => {
        try {
            const response = await callApi('GET', 'getConfiguracaoTaxa', { id_configuracao_taxa: 0, id_configuracao: id_configuracao });
            setConfiguracaoTaxas(response);
        } catch (error) {
            console.error('Erro ao obter configurações de taxa:', error);
        }
    };

    const showMessage = (text, variant) => {
        setMessage({ text, variant });
        setTimeout(() => {
            setMessage(null);
        }, 5000);
    };

    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    return (
        <Container className="cadastro-form">
            <h2 className="title">Cadastro de Configuração</h2>
            {message && (
                <Alert variant={message.variant} onClose={() => setMessage(null)} dismissible>
                    {message.text}
                </Alert>
            )}
            <Row>
                <Col md={6}>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formNome">
                            <Form.Label>Nome</Form.Label>
                            <Form.Control
                                type="text"
                                name="nome"
                                value={configuracao.nome}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formDescricao">
                            <Form.Label>Descrição</Form.Label>
                            <Form.Control
                                type="text"
                                name="descricao"
                                value={configuracao.descricao}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formStatus">
                            <Form.Label>Status</Form.Label>
                            <Form.Control
                                type="text"
                                name="status"
                                value={configuracao.status}
                                onChange={handleChange}
                                required
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="submit-button">
                            Cadastrar
                        </Button>
                    </Form>
                </Col>
                <Col md={6}>
                    <Form.Group controlId="formSearch">
                        <Form.Label>Filtrar</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Digite o nome ou descrição para filtrar"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Form.Group>
                    <Table striped bordered hover className="mt-4">
                        <thead>
                            <tr>
                                <th>Nome</th>
                                <th>Descrição</th>
                                <th>Status</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentConfiguracoes.map((configuracao) => (
                                <tr key={configuracao.id_configuracao}>
                                    <td>{configuracao.nome}</td>
                                    <td>{configuracao.descricao}</td>
                                    <td>{configuracao.status}</td>
                                    <td>
                                        <Button variant="info" onClick={() => handleEdit(configuracao)}>Editar</Button>
                                        <Button variant="secondary" onClick={() => handleConfigurar(configuracao)} className="ml-2">Configurar</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={5}
                        totalItemsCount={filteredConfiguracoes.length}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                    />
                </Col>
            </Row>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Configurar Taxas</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleTaxaSubmit}>
                        <Form.Group controlId="formDe">
                            <Form.Label>De</Form.Label>
                            <InputMask
                                mask="99:99"
                                name="de"
                                value={configuracaoTaxa.de}
                                onChange={handleTaxaChange}
                                className="form-control"
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formAte">
                            <Form.Label>Até</Form.Label>
                            <InputMask
                                mask="99:99"
                                name="ate"
                                value={configuracaoTaxa.ate}
                                onChange={handleTaxaChange}
                                className="form-control"
                                required
                            />
                        </Form.Group>
                        <Form.Group controlId="formValor">
                            <Form.Label>Valor</Form.Label>
                            <Form.Control
                                type="text"
                                name="valor"
                                value={configuracaoTaxa.valor}
                                onChange={handleTaxaChange}
                                required
                            />
                        </Form.Group>
                        <Button variant="primary" type="submit" className="submit-button">
                            Salvar
                        </Button>
                    </Form>
                    <Table striped bordered hover className="mt-4">
                        <thead>
                            <tr>
                                <th>De</th>
                                <th>Até</th>
                                <th>Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            {configuracaoTaxas.map((taxa) => (
                                <tr key={taxa.id_configuracao_taxa}>
                                    <td>{taxa.de}</td>
                                    <td>{taxa.ate}</td>
                                    <td>{taxa.valor}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Fechar</Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default CadastroConfiguracao;
