import React, { useState, useRef, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Table, Image, Modal } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import Webcam from 'react-webcam';
import Pagination from 'react-js-pagination';
import './cadastro.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faPlus, faPrint, faCheckCircle, faStar } from '@fortawesome/free-solid-svg-icons';


const CadastroResponsavelCrianca = ({ callApi }) => {
    const [cpf, setCpf] = useState('');
    const [responsavel, setResponsavel] = useState(null);
    const [criancas, setCriancas] = useState([]);
    const [showFormResponsavel, setShowFormResponsavel] = useState(false);
    const [tags, setTags] = useState('');
    const [planoEntrada, setPlanoEntrada] = useState('');
    const [morador, setMorador] = useState(false);
    const [vinculo, setVinculo] = useState('');
    const [criancaSelecionada, setCriancaSelecionada] = useState({ id: 0, nome: '' });

    const [novoResponsavel, setNovoResponsavel] = useState({
        nome: '',
        telefone: '',
        photo_base64: ''
    });
    const [fechamentoData, setFechamentoData] = useState(null);
    const [showFechamentoModal, setShowFechamentoModal] = useState(false);

    const [formaPagamento, setFormaPagamento] = useState(1);

    const [showModal, setShowModal] = useState(false);
    const [nomeCrianca, setNomeCrianca] = useState('');
    const [idadeCrianca, setIdadeCrianca] = useState('');
    const [capturedImage, setCapturedImage] = useState(null); // State para armazenar a imagem capturada
    const webcamRef = useRef(null); // Referência única para a webcam
    const [entradas, setEntradas] = useState([]);
    const [filteredEntradas, setFilteredEntradas] = useState([]);
    const [activePage, setActivePage] = useState(1);

    const [showComprovante, setShowComprovante] = useState(false);
    const [comprovanteData, setComprovanteData] = useState(null);
    const [message, setMessage] = useState(null);

    const [showModalEntrar, setShowModaEntrar] = useState(false);

    const indexOfLastItem = activePage * 5;
    const indexOfFirstItem = indexOfLastItem - 5;
    const [searchTerm, setSearchTerm] = useState('');
    const currentEntradas = filteredEntradas.slice(indexOfFirstItem, indexOfLastItem);




    const handleCpfChange = (e) => {
        setCpf(e.target.value);
    };

    const showMessage = (text, variant) => {
        setMessage({ text, variant });
        setTimeout(() => {
            setMessage(null);
        }, 5000);
    };
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
    };

    const formatCpf = (cpf) => {
        return cpf.slice(0, 3) + '.XXX.XXX-' + cpf.slice(-2);
    };

    const calculatePrice = (minutes) => {
        let total = 0;
        if (minutes <= 30) {
            total = 45;
        } else if (minutes <= 60) {
            total = 75;
        } else if (minutes <= 180) {
            total = 100;
        } else {
            total = 100 + (minutes - 180) * 2;
        }
        return total;
    };



    const generateComprovante = async (id_crianca_tag) => {
        try {
            const response = await callApi('GET', 'getCriancaTag', { id_crianca_tag });
            setComprovanteData(response[0]);
            setShowComprovante(true);
            printComprovante(response[0]);
        } catch (error) {
            console.error('Erro ao gerar comprovante:', error);
        }
    };
    const handleClose = () => {
        setShowModaEntrar(false);
        // Lógica para fechar o modal
    };

    const calculateDiscount = (total, isMorador) => {
        if (!isMorador) return 0;

        if (total <= 45) {
            return 10;
        } else if (total <= 75) {
            return 15;
        } else {
            return 20;
        }
    };

    const printComprovante = (data) => {
        const vinculo = data.vinculo ? data.vinculo.toUpperCase() : '';
        const printWindow = window.open('', '_blank');
        printWindow.document.write(`
            <html>
                <head>
                    <style>
                        @page { size: 80mm auto; margin: 0mm; }
                        body {
                            width: 80mm;
                            font-family: Arial, sans-serif;
                            margin: 0;
                            padding: 0;
                        }
                        .comprovante {
                            padding: 10mm;
                        }
                        .comprovante img {
                            width: 100%;
                        }
                        .comprovante p {
                            margin: 0;
                            padding: 0;
                        }
                    </style>
                </head>
                <body>
                    <div class="comprovante">
                        <img src="logo_preta.png" width="130px" alt="Logo" />
                        <hr>
                        <p style='font-size:16px;'>CÓDIGO ENTRADA: ${data.id_crianca_tag}</p>
                        <hr>
                        <p>RESPONSÁVEL:<br> ${data.nome_responsavel}</p>
                        <hr>
                        <p>CRIANÇA:<br> ${data.nome_crianca}</p>
                        <hr>
                        <p>VÍNCULO:<br> ${vinculo}</p>
                        <hr>
                         <p>PLANO:<br> ${data.plano_adquirido}</p>
                        <hr>
                        <p>ENTRADA:<br> ${formatDateTime(data.inicio)}</p>
                    </div>
                </body>
            </html>
        `);
        printWindow.document.close();
        printWindow.focus();
        setTimeout(() => {
            printWindow.print();
            printWindow.close();
        }, 1000);
    };

    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        date.setHours(date.getHours() + 3); // Adiciona 3 horas
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        };
        return date.toLocaleString('pt-BR', options);
    };

    const handleBuscar = async () => {
        try {
            const response = await callApi('GET', 'getResposnavel', { id_responsavel: 0, cpf: cpf });

            if (response && response.length > 0) {
                setResponsavel(response[0]);
                const criancasResponse = await callApi('GET', 'getCrianca', { id_crianca: 0, id_responsavel: response[0].id_responsavel, cpf: '' });
                setCriancas(criancasResponse);
                setShowFormResponsavel(false);
            } else {
                setResponsavel(null);
                setCriancas([]);
                setShowFormResponsavel(true);
            }
        } catch (error) {
            alert('Erro ao buscar responsável.');
        }
    };


    const handleFinalizeSubmit = async () => {
        try {
            const total = calculatePrice(fechamentoData.minutos_passados);
            const discount = calculateDiscount(total, fechamentoData.morador);
            const totalWithDiscount = total - discount;

            //alert(`Total: ${total}, Desconto: ${discount}, Total com Desconto: ${totalWithDiscount}`); // Alert para verificar valores

            await callApi('POST', 'insertFechamento', {
                id_fechamento: 0,
                id_crianca_tag: fechamentoData.id_crianca_tag,
                id_configuracao_taxa: fechamentoData.id_configuracao_taxa,
                valor_total: totalWithDiscount,
                forma_pagamento: formaPagamento // ou qualquer forma de pagamento desejada
            });
            setShowFechamentoModal(false);
            printComprovanteFechamento(fechamentoData, total, discount, totalWithDiscount);
            showMessage('Entrada finalizada com sucesso!', 'success');
            fetchEntradas();
        } catch (error) {
            showMessage('Erro ao finalizar entrada.', 'danger');
        }
    };

    const printComprovanteFechamento = (data, total, discount, totalWithDiscount) => {
        const isMorador = data.morador === 1;

        const printWindow = window.open('', '_blank');
        printWindow.document.write(`
            <html>
                <head>
                    <style>
                        @page { size: 80mm auto; margin: 0mm; }
                        body {
                            width: 80mm;
                            font-family: Arial, sans-serif;
                            margin: 0;
                            padding: 0;
                        }
                        .comprovante {
                            padding: 10mm;
                        }
                        .comprovante img {
                            width: 100%;
                        }
                        .comprovante p {
                            margin: 0;
                            padding: 0;
                        }
                    </style>
                </head>
                <body>
                    <div class="comprovante">
                        <img src="logo_preta.png" width="130px" alt="Logo" />
                        <hr>
                        <p style='font-size:16px;'>CÓDIGO ENTRADA: ${data.id_crianca_tag}</p>
                        <hr>
                        <p>RESPONSÁVEL:<br> ${data.nome_responsavel}</p>
                        <hr>
                        <p>CRIANÇA:<br> ${data.nome_crianca}</p>
                        <hr>
                        <p>ENTRADA:<br> ${data.hora_inicio}</p>
                        <hr>
                        <p>SAÍDA:<br> ${data.hora_final}</p>
                        <hr>
                        <p>TEMPO TOTAL:<br> ${data.minutos_passados} MINUTOS</p>
                        <hr>
                        <p>VALOR TOTAL:<br> R$ ${total}</p>
                        ${isMorador ? `<hr><p>DESCONTO:<br> R$ ${discount}</p>` : ''}
                        <hr>
                        <p>VALOR A SER PAGO:<br> R$ ${totalWithDiscount}</p>
                        <hr>
                        <p>OBRIGADO, VOLTE SEMPRE!</p>
                    </div>
                </body>
            </html>
        `);
        printWindow.document.close();
        printWindow.focus();
        setTimeout(() => {
            printWindow.print();
            printWindow.close();
        }, 1000);
    };


    const handleNovoResponsavelChange = (e) => {
        const { name, value } = e.target;
        setNovoResponsavel(prevState => ({ ...prevState, [name]: value }));
    };

    const handleSalvarResponsavel = async () => {
        try {
            const data = { ...novoResponsavel, cpf };
            if (capturedImage) {
                data.photo_base64 = capturedImage; // Salva a imagem capturada
            }

            data.rg = '';
            data.sexo = '';
            data.endereco = '';
            data.cidade = '';
            data.bairro = '';
            data.estado = '';
            data.email = '';
            data.data_nascimento = '2000-01-01';

            if (!data.id_responsavel || data.id_responsavel === 0) {
                data.id_responsavel = 0;
            }
            await callApi('POST', 'insertResponsavel', data);
            alert('Responsável cadastrado com sucesso!');
            setResponsavel(data);
            setNovoResponsavel({ nome: '', telefone: '', photo_base64: '' });
            setShowFormResponsavel(false);

            // Após salvar, realizar nova busca com o CPF atualmente inserido
            handleBuscar();
        } catch (error) {
            alert('Erro ao cadastrar responsável.');
        }
    };

    const handleNomeCriancaChange = (e) => {
        setNomeCrianca(e.target.value);
    };

    const handleIdadeCriancaChange = (e) => {
        setIdadeCrianca(e.target.value);
    };


    const calculateElapsedTime = (startTime) => {
        const now = new Date();
        let start = new Date(startTime);
         start.setHours(start.getHours() + 3);
        const elapsed = (now - start) / 1000;

        const isNegative = elapsed < 0;

        const absElapsed = Math.abs(elapsed);

        const hours = Math.floor(absElapsed / 3600);
        const minutes = Math.floor((absElapsed % 3600) / 60);
        const seconds = Math.floor(absElapsed % 60);

        return `${isNegative ? '-' : ''}${hours}h ${minutes}m ${seconds}s`;
    };


    const handlePrint = async (id_crianca_tag) => {
        try {
            const response = await callApi('GET', 'getCriancaTag', { id_crianca_tag });
            setComprovanteData(response[0]);
            setShowComprovante(true);
            printComprovante(response[0]);
        } catch (error) {
            console.error('Erro ao gerar comprovante:', error);
        }
    };

    const handleFinalize = async (id_crianca_tag) => {
        try {
            const response = await callApi('GET', 'getGerarFechamento', { id_crianca_tag: id_crianca_tag });
            setFechamentoData(response[0]);
            setShowFechamentoModal(true);
        } catch (error) {
            showMessage('Erro ao finalizar entrada.', 'danger');
        }
    };


    const handleTirarFoto = () => {
        setShowModal(true);
    };

    const handleFecharModal = () => {
        setShowModal(false);
    };

    const capture = () => {
        if (webcamRef.current) {
            const imageSrc = webcamRef.current.getScreenshot();
            setCapturedImage(imageSrc); // Armazena a imagem capturada no estado
            setShowModal(false); // Fecha o modal de captura após capturar a imagem
        }
    };
    useEffect(() => {

        fetchEntradas();
        const interval = setInterval(() => {
            setEntradas(prevEntradas => [...prevEntradas]);
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const fetchEntradas = async () => {
        try {
            const response = await callApi('GET', 'getCriancaTag', { id_crianca_tag: 0, id_crianca: 0, id_tag: 0, id_equipamento: 0, inicio: '' });
            setEntradas(response);
            setFilteredEntradas(response);
        } catch (error) {
            console.error('Erro ao obter entradas:', error);
        }
    };

    const handleSalvarCrianca = async () => {
        try {
            const data = {
                id_crianca: 0,
                cpf: '',
                sexo: '',
                data_nascimento: '2000-01-01',
                photo_base64: capturedImage, // Salva a imagem capturada
                nome: nomeCrianca,
                idade: idadeCrianca,
                id_responsavel: responsavel.id_responsavel
            };
            await callApi('POST', 'insertCrianca', data);
            alert('Criança cadastrada com sucesso!');
            // Atualiza a lista de crianças após o cadastro
            const criancasResponse = await callApi('GET', 'getCrianca', { id_crianca: 0, id_responsavel: responsavel.id_responsavel, cpf: '' });
            setCriancas(criancasResponse);
            setNomeCrianca('');
            setIdadeCrianca('');
            setCapturedImage(null); // Limpa a imagem capturada após o cadastro
        } catch (error) {
            alert('Erro ao cadastrar criança.');
        }
    };

    const handleEntrar = (idCrianca, nomeCrianca) => {
        setCriancaSelecionada({ id: idCrianca, nome: nomeCrianca });
        setShowModaEntrar(true);
    };


    const handleSave = async () => {
        const inicio = new Date();
        inicio.setMinutes(inicio.getMinutes() + 5); // Adiciona 5 minutos
        //inicio.setHours(inicio.getHours() - 3); // Subtrai 3 horas


        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Os meses começam do 0
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

        const formattedInicio = formatDate(inicio);

        var flag_morador = 0;
        if (morador)
            flag_morador = 1;

        const data = {
            id_crianca: criancaSelecionada.id,
            tag: tags,
            id_equipamento: 1,
            inicio: formattedInicio,
            vinculo: vinculo,
            morador: flag_morador,
            plano: planoEntrada
        };

        try {
            await callApi('POST', 'insertCriancaTag', data);
            fetchEntradas();
            handleCloseModal();
        } catch (error) {
            console.error('Erro ao salvar a entrada:', error);
        }
    };


    const handleCloseModal = () => {
        setShowModaEntrar(false);
        setTags('');
        setPlanoEntrada('');
        setMorador(false);
        setVinculo('');
    };
    const handleRecarregar = () => {
        window.location.reload(); // Recarrega a página
    };

    return (
        <Container className="cadastro-form">
            <Row>
                <Col md={7}>
                    
                    <Form>
                        <Form.Group controlId="formCpf">
                            <Form.Label>CPF do Responsável</Form.Label>
                            <InputMask
                                mask="999.999.999-99"
                                value={cpf}
                                onChange={handleCpfChange}
                                className="form-control"
                                placeholder="Digite o CPF"
                                required
                            />
                        </Form.Group>
                        <Button variant="primary" onClick={handleBuscar}>
                            Buscar
                        </Button>
                        <Button variant="secondary" onClick={handleRecarregar} className="ml-2">
                            Recomeçar
                        </Button>
                    </Form>

                    {responsavel && (
                        <div className="mt-4">
                            <Row>
                                <Col md={3}>
                                    <Image src={`https://igmportariaremota.com.br:3939/responsavel/${responsavel.id_responsavel}.jpg`} thumbnail style={{ maxHeight: '100px', width: 'auto' }} />
                                    <Form.Label>{responsavel.nome}</Form.Label>
                                    <Form.Label>{responsavel.telefone}</Form.Label>
                                </Col>
                                <Col md={5}>
                                    <Table striped bordered hover className="mt-4">
                                        <thead>
                                            <tr>
                                                <th>Nome da Criança</th>
                                                <th>Ações</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {criancas.length > 0 ? (
                                                criancas.map(crianca => (
                                                    <tr key={crianca.id_crianca}>
                                                        <td>{crianca.nome}</td>
                                                        <td>
                                                            <Button variant="success" onClick={() => handleEntrar(crianca.id_crianca, crianca.nome)}>Entrar</Button>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="2">Nenhuma criança cadastrada.</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col md={4}>
                                    <Form className="mt-4">
                                        <Row>
                                            <Col md={12}>
                                                <Form.Group controlId="formNomeCrianca">
                                                    <Form.Label>Nome da Criança</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={nomeCrianca}
                                                        onChange={handleNomeCriancaChange}
                                                        required
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Group controlId="formIdadeCrianca">
                                                    <Form.Label>Idade da Criança</Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        value={idadeCrianca}
                                                        onChange={handleIdadeCriancaChange}
                                                        required
                                                    />
                                                </Form.Group>
                                            </Col>
                                            {capturedImage && (
                                                <div className="mt-4">
                                                    <h5>Foto Capturada:</h5>
                                                    <Image src={capturedImage} thumbnail style={{ maxHeight: '200px', width: 'auto' }} />
                                                </div>
                                            )}
                                            <Row>
                                                <Col md={6}>
                                                    <Button variant="success" onClick={handleTirarFoto}>
                                                        Tirar Foto
                                                    </Button>
                                                </Col>
                                                <Col md={6}>
                                                    <Button variant="primary" onClick={handleSalvarCrianca}>
                                                        Salvar Criança
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Row>
                                    </Form>
                                </Col>


                            </Row>
                        </div>
                    )}

                    {showFormResponsavel && (
                        <Form className="mt-4">
                            <Row>
                                <Col md={4}>
                                    <Form.Group controlId="formNome">
                                        <Form.Label>Nome</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="nome"
                                            value={novoResponsavel.nome}
                                            onChange={handleNovoResponsavelChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group controlId="formTelefone">
                                        <Form.Label>Telefone</Form.Label>
                                        <InputMask
                                            mask="(99) 99999-9999"
                                            className="form-control"
                                            type="text"
                                            name="telefone"
                                            value={novoResponsavel.telefone}
                                            onChange={handleNovoResponsavelChange}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Button variant="primary" onClick={handleTirarFoto}>
                                        Tirar Foto
                                    </Button>
                                </Col>
                            </Row>
                            <Button variant="primary" onClick={handleSalvarResponsavel}>
                                Salvar Responsável
                            </Button>
                        </Form>
                    )}
                </Col>

                <Col md={5}>
                    <h3>Entradas em Aberto</h3>
                    <div className="total-entries mt-3">
                        <FontAwesomeIcon icon={faStar} size="2x" color="#ffd700" />
                        <span className="ms-2">Total de Entradas: {entradas.length}</span>
                        <span className="ms-2">Capacidade Máxima: 40</span>
                    </div>
                    <Form.Group controlId="formSearch" className="mb-3">
                        <Form.Control
                            type="text"
                            placeholder="Pesquisar..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </Form.Group>

                    <Table striped bordered hover className="mt-4">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Responsável</th>
                                <th>Criança</th>
                                <th>Telefone</th>
                                {/*<th>Data/Hora</th>*/}
                                <th>Tempo Decorrido</th>
                                <th>Ações</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentEntradas.map((entrada) => (

                                <tr key={entrada.id_crianca_tag} className={calculateElapsedTime(entrada.inicio).includes('-') ? 'over-three-hours' : ''}>
                                    <td>{entrada.id_crianca_tag}</td>
                                    <td>{entrada.nome_responsavel}</td>
                                    <td>{entrada.nome_crianca}</td>
                                    <td>{entrada.telefone}</td>
                                    {/* <td>{hora}</td>*/}
                                    <td>{calculateElapsedTime(entrada.inicio)}</td>
                                    <td>
                                        <Button variant="info" onClick={() => handlePrint(entrada.id_crianca_tag)} className="rounded-button gradient-button">
                                            <FontAwesomeIcon icon={faPrint} />
                                        </Button>
                                        <Button variant="secondary" onClick={() => handleFinalize(entrada.id_crianca_tag)} className="rounded-button gradient-button">
                                            <FontAwesomeIcon icon={faCheckCircle} />
                                        </Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={5}
                        totalItemsCount={entradas.length}
                        pageRangeDisplayed={5}
                        onChange={handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                    />

                </Col>
            </Row>

            <Modal show={showModal} onHide={handleFecharModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Tirar Foto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Webcam
                        audio={false}
                        ref={webcamRef} // Utiliza webcamRef para responsável
                        screenshotFormat="image/jpeg"
                        width="100%"
                        height="100%"
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleFecharModal}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={capture}>
                        Capturar
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showFechamentoModal} onHide={() => setShowFechamentoModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Finalizar Entrada</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {fechamentoData && (
                        <div>
                            <Row>
                                <Col md={6}>
                                    <img src={`https://www.igmportariaremota.com.br:3939/responsavel/${fechamentoData.id_responsavel}.jpg`} alt="Responsável" width="100%" />
                                </Col>
                                <Col md={6}>
                                    <img src={`https://www.igmportariaremota.com.br:3939/crianca/${fechamentoData.id_crianca}.jpg`} alt="Criança" width="100%" />
                                </Col>
                            </Row>
                            <hr></hr>
                            <Row>
                                <Col md={6}>
                                    <b>Responsável:</b>
                                    <p>
                                        {fechamentoData.nome_responsavel}<br></br>
                                        {formatCpf(fechamentoData.cpf_responsavel)}</p>
                                </Col>

                                <Col md={6}>
                                    <b>Criança:</b>
                                    <p>{fechamentoData.nome_crianca}<br></br>
                                        {formatCpf(fechamentoData.cpf_crianca)}</p>
                                </Col>
                            </Row>
                            <hr></hr>
                            <p>Tag:<b> {fechamentoData.tag}</b></p>
                            <p>Entrada:<b>  {fechamentoData.hora_inicio}</b></p>
                            <p>Saída:<b>  {fechamentoData.hora_final}</b></p>
                            <p>Tempo Total:<b>  {fechamentoData.minutos_passados} Minutos</b></p>
                            <p>Valor Total:<b>  R$ {calculatePrice(fechamentoData.minutos_passados)}</b></p>
                            {fechamentoData.morador === 1 && <p>Desconto:<b>  R$ {calculateDiscount(calculatePrice(fechamentoData.minutos_passados), fechamentoData.morador)}</b></p>}
                            <p>Valor a ser Pago:<b>  R$ {calculatePrice(fechamentoData.minutos_passados) - calculateDiscount(calculatePrice(fechamentoData.minutos_passados), fechamentoData.morador)}</b></p>
                            <Form.Group controlId="formFormaPagamento">
                                <Form.Label>Forma de Pagamento</Form.Label>
                                <Form.Control as="select" value={formaPagamento} onChange={(e) => setFormaPagamento(e.target.value)}>
                                    <option value="1">Dinheiro</option>
                                    <option value="2">Débito</option>
                                    <option value="3">Crédito</option>
                                    <option value="4">PIX</option>
                                    <option value="5">Voucher</option>
                                </Form.Control>
                            </Form.Group>
                            <Button variant="primary" onClick={handleFinalizeSubmit} className="mt-3">Finalizar</Button>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowFechamentoModal(false)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showModalEntrar} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Entrada</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group controlId="tags">
                            <Form.Label>Tags</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Insira tags"
                                value={tags}
                                onChange={(e) => setTags(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group controlId="planoEntrada">
                            <Form.Label>Plano de Entrada</Form.Label>
                            <Form.Control
                                as="select"
                                value={planoEntrada}
                                onChange={(e) => setPlanoEntrada(e.target.value)}
                            >
                                <option value="">Selecione um plano</option>
                                <option value="meia-hora">Pacote Meia Hora</option>
                                <option value="uma-hora">Pacote 1 Hora</option>
                                <option value="tres-horas">Pacote 3 Horas</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="morador">
                            <Form.Check
                                type="checkbox"
                                label="Morador de Poços de Caldas"
                                checked={morador}
                                onChange={(e) => setMorador(e.target.checked)}
                            />
                        </Form.Group>
                        <Form.Group controlId="vinculo">
                            <Form.Label>Vínculo</Form.Label>
                            <Form.Control
                                as="select"
                                value={vinculo}
                                onChange={(e) => setVinculo(e.target.value)}
                            >
                                <option value="">Selecione um vínculo</option>
                                <option value="pai">Pai</option>
                                <option value="mae">Mãe</option>
                                <option value="tio">Tio</option>
                                <option value="avo">Avô</option>
                                <option value="Irmao">Irmão</option>
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Fechar
                    </Button>
                    <Button variant="primary" onClick={handleSave}>
                        Salvar Entrada
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default CadastroResponsavelCrianca;
